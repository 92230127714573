<template>
    <div>
        <Introduction
            v-if="article['intro_title_' + lang]"
            class="bg-accent-3 mb-xxl"
            width="l"
            :actions="[]"
            :title="article['intro_title_' + lang]"
            :text="article['intro_text_' + lang]"
            :image1="article.intro_image"
        />

        <div
            class="c w-s"
            :class="{ 'mt-xxl pt-xxl': !article['intro_title_' + lang] }"
            v-html="article['config_' + lang]"
        />

        <div v-if="$route.name === 'Over Ons' && article.team && article.team.length" class="c w-xxl mt-xxl pt-xxl">
            <h3 class="color-accent mb-r">Ons team</h3>
            <Products
                :sorted="sortedTeam"
                :subtitleKey="'function_' + lang"
            />
        </div>
    </div>
</template>

<script>
import Introduction from '@/components/Introduction.vue';
import Products from '@/components/Products.vue';
import { strings, arrays } from 'p5-helpers';

export default {
    name: 'Article',
    components: {
        Introduction,
        Products,
    },
    data() {
        return {
            article: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => vm.getData());
    },
    beforeRouteUpdate(to, from, next) {
        next();
        this.getData();
    },
    methods: {
        getData() {
            if (window.PRERENDER_INJECTED) {
                const routedata = window.PRERENDER_INJECTED.routeData[this.$route.path];
                if (routedata) {
                    this.processData(routedata);
                }
            } else {
                const url = strings.replaceBrackets(this.$route.meta.article.endpoint, this.$route.params);
                this.$store.dispatch('get', url).then(this.processData);
            }
        },

        processData(r) {
            this.article = r;

            const lang = this.lang;
            this.$store.commit('setMeta', {
                data: {
                    title: r[`meta_title_${lang}`],
                    description: r[`meta_description_${lang}`],
                    keywords: (Array.isArray(r[`meta_keywords_${lang}`]) ? r[`meta_keywords_${lang}`] : []).map((it) => it.name).join(', '),
                },
            });
        },
    },
    computed: {
        lang() {
            return this.$i18n.locale;
        },

        teamSortOrder() {
            if (this.$route.name === 'Over Ons') {
                const data = this.$store.getters.data;
                if (data && data.sort && data.sort.length) {
                    const sort = data.sort.find((it) => it.table === 'team');
                    if (sort) {
                        return sort.sortedids;
                    }
                }
            }
            return false;
        },

        sortedTeam() {
            if (this.teamSortOrder) {
                return arrays.sortByArray({
                    data: this.article.team,
                    sortKey: 'id',
                    order: this.teamSortOrder,
                });
            }
            return this.article.team;
        },
    },
};
</script>
